<template>
  <div :class="[getWrapperClass(), $style.pageWrapper]">
    <LibHeroCanvas :color-mode="colorMode" />
    <LibErrorBoundary component-name="LibPageHeader">
      <LibPageHeader />
    </LibErrorBoundary>
    <LibErrorBoundary component-name="Page Content Slot">
      <main id="top" class="page-content">
        <slot />
      </main>
    </LibErrorBoundary>
    <LibErrorBoundary component-name="LibPageFooter">
      <LibPageFooter />
    </LibErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import type { ColorMode } from '@hypercodestudio/basler-components/dist/models/ColorMode';

type Props = {
  colorMode?: ColorMode;
};

const props = withDefaults(defineProps<Props>(), {
  colorMode: 'dark'
});

function getWrapperClass() {
  switch (props.colorMode) {
    case 'dark':
      return 'page-wrapper page-wrapper--dark';
    case 'dark-purple':
      return 'page-wrapper page-wrapper--dark-purple';
    case 'light':
      return 'page-wrapper page-wrapper--light';
    default:
      return 'page-wrapper';
  }
}
</script>

<style module>
.pageWrapper {
  /* overwrite existing styles */
  background: transparent !important;
}
</style>

<style>
.audiences-widget {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
